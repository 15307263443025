import { set } from 'lodash';
import React, { useState, useEffect } from 'react';

const ChefvTiers = ({ lang, expandTiers }) => {
  const [showTiers, setShowTiers] = useState(true);
  const [hideExpander, setHideExpander] = useState(false);

  const toggleTiers = () => {
    setShowTiers(!showTiers);
  };

  useEffect(() => {
    // on mount
    if (expandTiers) {
      setShowTiers(true);
      setHideExpander(true);
    }
  }, []);

  return (
    <div className={!hideExpander ? 'custom-tiers__breakdown' : 'custom-tiers__breakdown -slim'}>
      <div className={!hideExpander ? 'custom-tiers__inner' : 'custom-tiers__inner -slim'}>
        {!hideExpander
        && (
        <div className="custom-tiers__heading" onClick={(e) => toggleTiers()}>
          {'What\'s my ChefVLife tier?'}
          <a><img className={showTiers ? '-rotate' : ''} src="https://assets.heyethos.com/ethos/v2/ui/icon-down-arrow.svg" alt="What’s my Trendsetter tier?" /></a>
        </div>
        )}
        {showTiers
        && (
        <div className={!hideExpander ? 'custom-tiers__table' : 'custom-tiers__table -slim'}>
          <table>
            <tr>
              <th>
                Chef V Life
                <span>0-749 Points</span>
              </th>
              <th>
                Greenlife
                <span>750-2499 Points</span>
              </th>
              <th>
                ChefVIP
                <span>2500+ Points</span>
              </th>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>Free digital access to Chef V’s book, Making Cleaning Easier</li>
                  <li>Exclusive Recipes</li>
                  <li>Exclusive Content & Resources</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Free Immunity Boost</li>
                  <li>Exclusive Recipes</li>
                  <li>Exclusive Content & Resources</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Free 3 day cleanse</li>
                  <li>15 min 1:1 Check-in with Chef V</li>
                  <li>Exclusive Recipes</li>
                  <li>Exclusive Content & Resources</li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
        )}
      </div>
    </div>
  );
};
export default ChefvTiers;
